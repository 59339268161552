@tailwind base;
@tailwind utilities;
@tailwind components;

@layer base {
  * {
    font-feature-settings: 'salt';
  }
}

@layer components {
  .shape-sticker {
    shape-outside: circle(50%);
    clip-path: polygon(
      50% 0%,
      55.33% 7.84%,
      62.43% 1.57%,
      65.65% 10.48%,
      74.09% 6.19%,
      74.98% 15.62%,
      84.23% 13.55%,
      82.75% 22.91%,
      92.22% 23.21%,
      88.45% 31.9%,
      97.55% 34.55%,
      91.75% 42.04%,
      99.9% 46.86%,
      92.42% 52.67%,
      99.12% 59.37%,
      90.42% 63.13%,
      95.24% 71.29%,
      85.88% 72.77%,
      88.52% 81.87%,
      79.09% 80.98%,
      79.39% 90.45%,
      70.47% 87.24%,
      68.41% 96.49%,
      60.57% 91.16%,
      56.27% 99.61%,
      50% 92.5%,
      43.73% 99.61%,
      39.43% 91.16%,
      31.59% 96.49%,
      29.53% 87.24%,
      20.61% 90.45%,
      20.91% 80.98%,
      11.47% 81.87%,
      14.12% 72.77%,
      4.76% 71.29%,
      9.58% 63.13%,
      0.89% 59.37%,
      7.58% 52.67%,
      0.1% 46.86%,
      8.25% 42.04%,
      2.45% 34.55%,
      11.55% 31.9%,
      7.78% 23.21%,
      17.25% 22.91%,
      15.77% 13.55%,
      25.02% 15.62%,
      25.91% 6.19%,
      34.35% 10.48%,
      37.56% 1.57%,
      44.61% 7.84%,
      50% 0%
    );
  }
}

@layer utilities {
  .text-case-transform-upper {
    @apply uppercase;
  }

  .box.bg-hant-pink,
  .box.bg-primary-100 {
    @apply bg-red;
  }

  .box.sm\:bg-hant-pink,
  .box.sm\:bg-primary-100 {
    @apply sm:bg-red;
  }

  .box.bg-hant-yellow,
  .box.bg-yellow-100 {
    @apply bg-yellow;
  }

  .box.sm\:bg-hant-yellow,
  .box.sm\:bg-yellow-100 {
    @apply sm:bg-yellow;
  }

  .box.bg-hant-blue,
  .box.bg-blue-100 {
    @apply bg-blue;
  }

  .box.sm\:bg-hant-blue,
  .box.sm\:bg-blue-100 {
    @apply sm:bg-blue;
  }
}
